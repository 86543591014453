.slider-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.main-slide {
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: opacity 0.5s ease;
}

.fade-in {
  animation: fadeInAnimation 0.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.main-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.property-info {
  background: #fff;
  /*  position: absolute;
  bottom: 0; */
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease;
  justify-content: space-between;
}

.rilanci,
.timing {
  color: red;
  font-size: 18px;
  padding-inline: 20px;
  text-transform: uppercase;
}

.timing {
  text-align: left;
}

.rilanci {
  text-align: right;
}

.houseType {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  background: #6cc6b6;
  padding: 20px 60px;
  border-radius: 8px;
  font-size: 30px;
  font-weight: bold;
}

.codiceImmobile {
  position: absolute;
  z-index: 1;
  bottom: 80px;
  left: 10px;
  color: #6cc6b6;
  background: #fff;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.actualOffer {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 10px;
  background: #6cc6b6;
  color: #fff;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

/* .preview-slide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 200px;
  flex: 1;
  overflow-x: auto;
  transition: all 1s ease-out;
}

.preview-slide img {
  width: calc(10% - 0px);
  object-fit: cover;
  height: 100%;
  transition: all 1s ease-in;
  margin: 0;
} */

.preview-slide {
  display: flex;
  flex: 1;
  height: 180px;
  overflow-x: auto;
  overflow: hidden;
}

.preview-slide .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.preview-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
}



/* Loading */

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all 1s ease-in;
}

.loading-logo {
  width: 150px;
  height: auto;
}